.disclaimer-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.disclaimer {
    margin: 20px 0 50px 0;
    min-height: min(86vh, calc(100vh - 100px));
    width: 80%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: min(max(20px, calc(10px + 2vw)), 90px);
}

.disclaimer div {
    font-size: max(16px, 0.7em);
    margin: 20px 0;
}

.disclaimer h1 {
    font-size: 1em;
    margin: 25px 0 20px 0;
    font-weight: normal;
    line-height: normal;
}

.disclaimer h2 {
    font-size: 0.9em;
    margin: 30px 0 20px 0;
    font-weight: normal;
    line-height: normal;
}