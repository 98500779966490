.services-description-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.services-description {
    margin: 20px 0 50px 0;
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: min(max(20px, calc(10px + 2vw)), 90px);
}

.services-description img {
    width: 70%;
    min-width: 350px;
    max-height: 50vh;
    object-fit: contain;
    margin-bottom: 40px;
}

.services-description div {
    font-size: max(16px, 0.7em);
    margin: 20px 0;
}

.services-description h1 {
    font-size: 1em;
    margin: 25px 0 20px 0;
    font-weight: normal;
    line-height: normal;
}

.services-description h2 {
    font-size: 0.9em;
    margin: 30px 0 20px 0;
    font-weight: normal;
    line-height: normal;
}