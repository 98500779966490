.office-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.office {
    width: 100%;
}

.office-info {
    position: relative;
    top: 0;
    width: 100%;
    min-height: 500px;
    height: min(86vh, calc(100vh - 100px));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

.office-info-side {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: min(max(16px, calc(12px + 1.5vw)), 50px);
}

.office-info-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
}

.office-info-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.8);
}

.office-info-back img {
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
}

.office-content {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.office-title {
    width: 100%;
    font-size: 1em;
    margin-bottom: 20px;
}

.office-address {
    width: 100%;
    margin-bottom: 20px;
    font-size: 0.8em;
}

.office-address a {
    color: inherit;
    transition: color 0.3s;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: 0.7em;
}

.office-address a:hover {
    color: #0F62AF;
}

.office-phone {
    width: 100%;
    margin-bottom: 20px;
    font-size: 0.8em;
}

.office-phone a {
    color: inherit;
    transition: color 0.3s;
    font-size: 0.7em;
}

.office-phone a:hover {
    color: #0F62AF;
}

.office-hours {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.hours-title {
    font-size: 0.8em;
}

.office-day {
    font-size: 0.7em;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.office-info-side img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}