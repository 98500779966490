.home-container {
    width: 100%;
}

.home {
    width: 100%;
    margin-bottom: 5px;
}

.landing {
    position: relative;
    width: 100%;
    height: 86vh;
}

.landing-img-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0.3);
}

.landing img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -100;
}

.landing-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
}

.format-overlay {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.overlay-container {
    width: 90%;
    font-size: min(max(20px, calc(10px + 2vw)), 90px);
}

.landing-overlay-text {
    font-size: 1em;
    width: fit-content;
    margin-bottom: 30px;
}

.landing-overlay-request-container {
    color: inherit;
    text-decoration: none;
    padding: 15px;
    background-color: #0F62AF;
    cursor: pointer;
    transition: 0.3s;
}

.landing-overlay-request-container:hover {
    background-color: #0f508c;
}

.home-downarrow {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes bounce { 
    0%, 100% {-webkit-transform: translateY(0);}  
    50% {-webkit-transform: translateY(-15px);} 
 } 
 
 @keyframes bounce { 
    0%, 100% {transform: translateY(0);} 
    50% {transform: translateY(-15px);} 
 }

.home-downarrow img {
    object-fit: contain;
    object-position: center;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
    -webkit-animation-duration: 1s;
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
    height: 50%;
    opacity: 0.8;
    z-index: 0;
}