.request-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.request {
    width: 90%;
    max-width: 1500px;
    font-size: min(max(16px, calc(12px + 1.5vw)), 40px);
}

.spacing {
    margin-bottom: 30px;
}

.small-spacing {
    margin-bottom: 20px;
}

.request-title {
    font-size: 1.2em;
    margin-top: 30px;
}

.request-description {
    font-size: 0.8em;
}

.request-header {
    font-size: 0.9em;
    margin-bottom: 20px;
}

.request-header span {
    font-size: 0.7em;
    margin-left: 10px;
}

.request-form {
    width: 100%;
}

.request-form-section {
    width: 100%;
}

.request-form-section-required {
    width: 100%;
}

.request-form-section select {
    outline: none;
    border: none;
    border-bottom: 1px solid #0F62AF;
    font-size: 0.8em;
    color: #464646;
}

.request-form-section input {
    border: none;
    border-bottom: 1px solid #0F62AF;
    font-size: 0.8em;
    color: #464646;
}

.request-date-inputs {
    width: 100%;
}

.request-date-inputs select {
    width: 32%;
    margin-right: 1%;
    max-width: 300px;
    color: #464646;
}

.request-date-inputs select:focus,input:focus {
    outline: none;
}

.request-time-inputs {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.request-time-inputs input {
    margin-right: 1%;
    width: 20%;
    max-width: 100px;
    color: #464646;
    font-size: 0.7em;
}

.request-time-inputs div {
    margin-right: 1%;
    font-size: 0.8em;
}

.request-time-inputs select{
    margin-right: 1%;
    color: #464646;
    font-size: 0.7em;
}

.request-time-inputs select:focus{
    outline: none;
}



.request-name-inputs,.request-contact-inputs {
    width: 100%;
}

.request-name-inputs input,.request-contact-inputs input {
    width: 49%;
    max-width: 500px;
    margin-right: 1%;
    color: #464646;
}

.request-required-input {
    border: none;
    border-bottom: 1px solid #0F62AF;
    font-size: 0.8em;
    color: #464646;
}

.request-error-input {
    border: none;
    font-size: 0.8em;
    color: #464646;
    border-bottom: 1px solid #ff1900;
}


.request-reason-inputs {
    width: 100%;
}

.request-reason-inputs textarea {
    width: 100%;
    color: #464646;
    font-size: 0.8em;
    resize: none;
}

.request-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.request-button-container button {
    border: none;
    width: 30%;
    height: 60px;
    color: white;
    font-size: min(0.8em, 30px);
    background-color: #0F62AF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
}

.request-button-container button:hover{
    background-color: #2e77bb;
}

.request-notice{
    width: 100%;
    font-size: 1em;
}