.forms-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.forms {
    width: 90%;
    height: min(86vh, calc(100vh - 100px));
    display: flex;
    flex-direction: column;
    font-size: min(max(20px, calc(10px + 2vw)), 90px);
}

.forms-title {
    margin-top: 50px;
    width: 100%;
    font-size: 1em;
}

.forms-descriptions {
    font-size: 0.8em;
    margin-top: 20px;
    margin-bottom: 20px;
}

.forms-link {
    margin-top: 30px;
    padding: 10px 0;
    font-size: 0.8em;
    color: black;
    transition: 0.3s;
}

.forms-link:hover {
    color: #0F62AF;
}