.App-Container {
  margin-top: max(14vh, 100px);
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App {
  width:  100%;
  min-width: 350px;
  max-width: 2500px;
  min-height: min(86vh, calc(100vh - 100px));
}
