.footer {
    width: 100%;
    background-color: #0F62AF;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
}

.foot-link {
    min-width: 200px;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 5px 10px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
}

.foot-link:hover {
    color: black;
}


.footer-copyright {
    color: white;
}


