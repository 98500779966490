.services-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: min(max(20px, calc(10px + 2vw)), 90px);
}

.services-title {
    width: 100%;
    height: min(86vh, calc(100vh - 100px));
    display: flex;
    align-items: center;
    flex-direction: column;
}

.services-overlay-container {
    position: relative;
    width: 100%;
    height: calc(100% - 100px);
}

.services-overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
}

.services-overlay-image img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
}

.services-title-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1em;
}

.services-downarrow {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes bounce { 
    0%, 100% {-webkit-transform: translateY(0);}  
    50% {-webkit-transform: translateY(-15px);} 
 } 
 
 @keyframes bounce { 
    0%, 100% {transform: translateY(0);} 
    50% {transform: translateY(-15px);} 
 }

.services-downarrow img {
    animation-iteration-count: infinite;
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
    -webkit-animation-duration: 1s;
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
    height: 30%;
}

.services-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1000px) {
    .services-item {
        width: calc(100% / 2);
        height: max(40vh, 400px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1001px) {
    .services-item {
        width: calc(100% / 4);
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.services-item-inner-container {
    position: relative;
    width: 99%;
    height: 99%;
    cursor: pointer;
}

.services-item-inner-container:hover .services-item-image-container{
    background-color: rgb(0, 0, 0, 0.8);
}

.services-item-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    transition: 0.5s;
}


.services-item-image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
}

.services-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: white;
}

.services-item-name {
    width: 90%;
    text-decoration: none;
}

.services-item-button {
    width: 90%;
    text-decoration: underline;
}