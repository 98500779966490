@font-face {
  font-family: 'Karla';
  src: url('../Assets/Karla/static/Karla-Regular.ttf');
}

body {
  margin: 0;
  font-family: Karla, -apple-system, BlinkMacSystemFont, 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
