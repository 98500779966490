.site-overview {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.overview-section {
    width: 100%;
    height: min(86vh, calc(100vh - 100px));
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.overview-side {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(max(16px, calc(8px + 2vw)), 60px);
}


#overview-logo {
    width: 80%;
}

.welcome-text {
    font-size: 0.6em;
    width: 80%;
}

.overview-header {
    background-color: #0F62AF;
    font-size: min(max(16px, calc(8px + 2vw)), 60px);
    padding: 15px 30px;
    text-decoration: none;
    text-align: center;
    transition: 0.5s;
    color: white;
    text-align: center;
}

.overview-header:hover {
    background-color: #3a75ac;
}

.overview-full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
